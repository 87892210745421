import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Header from "../HeaderComponents/Header";
import urlConfig from '../../urlConfig';

const SeatMap = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [seatMapData, setSeatMapData] = useState(null);
    const [selectedSeats, setSelectedSeats] = useState(location.state?.selectedSeats || []);
    const Customer = JSON.parse(localStorage.getItem("user"));
    const {date, eventid} = location.state || {};

    const fetchSeatMapData = (i) => {
        fetch(`${urlConfig.apiBaseUrl}/Date/${i}`)
            .then(response => response.json())
            .then(data => {
                setSeatMapData(data);
            })
            .catch(error => console.error('Error fetching seating plans:', error));
    };

    useEffect(() => {
        if (date) {
            fetchSeatMapData(date.id);
        }
    }, [date]);

    const buySelectedSeats = async () => {
        if (!selectedSeats) return;
        if (!Customer) {
            navigate("/login", {
                state: {
                    redirectTo: "/seatingPlan",
                    seatingPlanState: {selectedSeats, date, eventid},
                }
            });
            return;
        }

        setSelectedSeats([]); // Reset selected seats
        const UUIDs = await Promise.all(
            selectedSeats.map((seat) =>
                fetch(`${urlConfig.apiBaseUrl}/ticket`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "customerID": Customer.id,
                        "eventID": eventid,
                        "dateID": date.id,
                        "seatID": seat.id
                    })
                })
                    .then(response => response.text())
                    .catch(error => {
                        console.error('Error sending POST request:', error);
                        return null;
                    })
            )
        );
        navigate("/qrcode", {state: UUIDs});
    };

    const toggleSeat = (seat, isAvailable, rowNumber) => {
        if (isAvailable) {
            setSelectedSeats((prevSelected) => {
                const seatIndex = prevSelected.findIndex(s => s.id === seat.id);
                if (seatIndex === -1) {
                    return [...prevSelected, {...seat, rowNumber}];
                } else {
                    return prevSelected.filter(s => s.id !== seat.id);
                }
            });
        }
    };

    const generateSeatMap = () => {
        if (!seatMapData || !seatMapData.seatingPlan || !seatMapData.seatingPlan.seatingGroups) {
            return null;
        }

        const svgWidth = 800;
        const svgHeight = 400;
        const minSpacing = 10;
        const offset = 20;
        const rows = seatMapData.seatingPlan.seatingGroups[0].rows.length;
        const seatsPerRow = seatMapData.seatingPlan.seatingGroups[0].rows[0].seats.length;

        const totalSpacingX = (seatsPerRow + 1) * minSpacing + offset;
        const totalSpacingY = (rows + 1) * minSpacing + offset;

        const seatWidth = (svgWidth - totalSpacingX) / seatsPerRow;
        const seatHeight = (svgHeight - totalSpacingY) / rows;
        const seatSize = Math.min(seatWidth, seatHeight);

        const rowLabels = seatMapData.seatingPlan.seatingGroups[0].rows.map((row, rowIndex) => {
            const y = (rowIndex + 1) * minSpacing + rowIndex * seatSize + seatSize / 2;
            return (
                <text key={`${rowIndex}`} x={5} y={y + offset} fontFamily="Arial" fontSize="20" fill="black"
                      textAnchor="middle" alignmentBaseline="middle">
                    {row.rowNumber}
                </text>
            );
        });

        const seatLabels = Array.from({length: seatsPerRow}, (_, seatIndex) => {
            const x = (seatIndex + 1) * minSpacing + seatIndex * seatSize + seatSize / 2;
            return (
                <text key={`${seatIndex}`} x={x + offset} y={minSpacing} fontFamily="Arial" fontSize="20" fill="black"
                      textAnchor="middle" alignmentBaseline="middle">
                    {seatIndex + 1}
                </text>
            );
        });

        const svgElements = seatMapData.seatingPlan.seatingGroups[0].rows.map((row, rowIndex) =>
            row.seats.map((seat, seatIndex) => {
                const x = (seatIndex + 1) * minSpacing + seatIndex * seatSize + offset;
                const y = (rowIndex + 1) * minSpacing + rowIndex * seatSize + offset;
                const seatId = seat.seatNumber;
                const isAvailable = seat.status === "AVAILABLE";
                const fillColor = isAvailable ? (selectedSeats.some(s => s.id === seat.id) ? 'yellow' : 'white') : 'red';

                return (
                    <g key={seat.id} onClick={() => toggleSeat(seat, isAvailable, row.rowNumber)}>
                        <rect id={seatId} x={x} y={y} width={seatSize} height={seatSize} fill={fillColor}
                              stroke="black"/>
                    </g>
                );
            })
        );

        return (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={svgWidth} height={svgHeight}>
                {rowLabels}
                {seatLabels}
                {svgElements}
            </svg>
        );
    };

    const resetPurchasedSeats = () => {
        date.seatingPlan.seatingGroups.forEach(group => {
            group.rows.forEach(row => {
                row.seats.forEach(seat => {
                    seat.status = "AVAILABLE";
                });
            });
        });

        fetch(`${urlConfig.apiBaseUrl}/Date/${date.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(date.seatingPlan)
        })
            .then(response => {
                if (response.ok) {
                    console.log(`resetPurchasedSeats succeeded with status: ${response.status}`);
                } else {
                    console.error(`resetPurchasedSeats failed with status: ${response.status}, ${response.statusText}`);
                }
            })
            .catch(error => {
                console.error('Error during fetching resetPurchasedSeats:', error);
            });
    };

    return (
        <div>
            <Header/>
            <div className="flex justify-between mt-20 ml-14 gap-4">
                <div className="w-9/12 pr-4 border-r border-gray-300">
                    <div className="mt-[2%]" id="seatmapContainer">{generateSeatMap()}</div>
                    <button
                        className="absolute bottom-2 left-2 bg-gray-600 text-gray-300 py-2 px-4 rounded text-sm hover:bg-gray-400"
                        onClick={resetPurchasedSeats}>
                        Reset Purchased Seats
                    </button>
                </div>
                <div className="w-2/5 pl-4 flex flex-col justify-between mt-14 items-center">
                    <h2 id="selectedSeatsTitle" className="w-full">Selected Seats</h2>
                    <ul>
                        {selectedSeats.map((seat) => (
                            <li key={seat.id}>{`Row ${seat.rowNumber}, Seat ${seat.seatNumber} - € ${seat.type.price},-`}</li>
                        ))}
                    </ul>
                    <button className="mt-auto bg-blue-500 text-white py-2 px-4 rounded cursor-pointer"
                            onClick={buySelectedSeats}>
                        {`${selectedSeats.length} Tickets for €${selectedSeats.reduce((total, seat) => total + seat.type.price, 0)}`}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SeatMap;
