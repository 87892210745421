import React, { useState } from 'react';
import urlConfig from '../../urlConfig';

const MolliePaymentDemo = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const createPayment = async () => {
        setIsLoading(true);
        setError('');
        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/api/create-payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            if (data.links && data.links.checkoutUrl) {
                window.location.href = data.links.checkoutUrl;
            } else {
                throw new Error('No checkout URL received');
            }
        } catch (err) {
            setError('Payment creation failed: ' + err.message);
            setIsLoading(false);
        }
    };

    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h2>Mollie Payment Demo</h2>
            <p>Create a payment and redirect to the Mollie checkout page</p>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            <button
                onClick={createPayment}
                disabled={isLoading}
                style={{ padding: '10px 20px', fontSize: '16px' }}
            >
                {isLoading ? 'Processing Payment...' : 'Pay 10.00 EUR'}
            </button>
        </div>
    );
};

export default MolliePaymentDemo;
