import React, { useEffect, useState } from 'react';
import Event from './Event';
import urlConfig from '../../urlConfig';

const EventList = () => {
    const [events, setEvents] = useState([]);

    const fetchEvents = async () => {
        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/Event`, {
                headers: {
                    'Accept': '*/*',
                },
            });
            const data = await response.json();
            const formattedEvents = data.map(event => {
                const eventDates = event.eventDates;  // Liste an Terminen für Events

                if (eventDates.length > 0) {
                    const startDate = new Date(eventDates[0].date);
                    const endDate = new Date(eventDates[eventDates.length - 1].date);

                    const formattedStartDate = startDate.toLocaleDateString();
                    const formattedEndDate = endDate.toLocaleDateString();
                    const dateRange = `${formattedStartDate} - ${formattedEndDate}`;  // kurze range (von - bis)
                    console.log("(EventList.jsx)DateRange: " + dateRange);
                    console.log(eventDates[0]);

                    return {
                        id: event.id,
                        imgSrc: event.picture,
                        title: event.name,
                        description: event.description,
                        detailedDescription: event.detailed_description,
                        organizer: event.organizer.name,
                        dateRange: dateRange,
                        eventDates: eventDates, // Hier wird eventDates hinzugefügt
                        eventLocation: eventDates[0].location.description,
                    };
                } else {
                    return null;
                }
            });
            setEvents(formattedEvents);
        } catch (error) {
            console.error('Fehler beim Abrufen der Events:', error);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    return (
        <section className="flex flex-col gap-8">
            <h2 className="text-left text-3xl ml-5 mb-0 font-semibold pt-[2%]">Events:</h2>
            {events.length > 0 ? (
                events.map((event, index) => (
                    <Event
                        id={event.id}
                        key={index}
                        imgSrc={event.imgSrc}
                        title={event.title}
                        description={event.description}
                        detailedDescription={event.detailedDescription}
                        organizer={event.organizer}
                        dateRange={event.dateRange}
                        eventDates={event.eventDates}
                        eventLocation={event.eventLocation}
                    />
                ))
            ) : (
                <p>Events werden geladen...</p>
            )}
        </section>
    );
};

export default EventList;