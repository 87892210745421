import React, {useEffect, useState} from "react";
import {QRCodeCanvas} from "qrcode.react";
import {useLocation} from "react-router-dom";
import Header from "../HeaderComponents/Header";
import urlConfig from '../../urlConfig';

const QRCodeDemo = () => {
    const location = useLocation();
    const UUIDs = location.state || [];
    const [ticketDetails, setTicketDetails] = useState([]);

    useEffect(() => {
        const fetchTicketDetails = async () => {
            const fetchedDetails = await Promise.all(
                UUIDs.map((UUID) =>
                    fetch(`${urlConfig.apiBaseUrl}/ticket/UUID?uuid=${UUID}`, {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                        },
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error(`Failed to fetch ticket with UUID: ${UUID}`);
                            }
                            return response.json();
                        })
                        .catch((error) => {
                            console.error("Error fetching ticket details:", error);
                            return null;
                        })
                )
            );
            setTicketDetails(fetchedDetails.filter((detail) => detail !== null));
        };

        fetchTicketDetails();
    }, [UUIDs]);

    return (
        <div className="flex flex-col items-center">
            <div className="w-full mx-0 mb-[6%]">
                <Header/>
            </div>

            <h2 className="mb-5 text-xl font-bold">Ihre Tickets! (Scan beim Eingang)</h2>

            <div className="flex flex-col gap-8 w-full max-w-4xl">
                {ticketDetails.map((ticket, index) => (
                    <div
                        key={index}
                        className="flex items-center justify-between bg-gray-100 p-6 rounded-lg shadow-md"
                    >
                        {/* QR Code */}
                        <div className="mr-6">
                            <QRCodeCanvas value={UUIDs[index]} size={128}/>
                        </div>

                        {/* Ticket Informationen */}
                        <div className="flex flex-col w-full">
                            {/* Ticket-Header: Abgehoben */}
                            <h3 className="text-2xl font-extrabold underline mb-4 font-serif">
                                Ticket {index + 1}
                            </h3>
                            <div className="grid grid-cols-2 gap-x-8">
                                {/* Linke Spalte */}
                                <div className="space-y-2">
                                    <p>
                                        <span className="font-semibold">Event:</span> {ticket.eventName}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Datum:</span>{" "}
                                        {new Date(ticket.eventDate).toLocaleDateString("de-DE")}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Kaufdatum:</span>{" "}
                                        {new Date(ticket.buyingDate).toLocaleDateString("de-DE")}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Preis:</span> €{ticket.price},-
                                    </p>
                                </div>

                                {/* Rechte Spalte */}
                                <div className="space-y-2">
                                    <p>
                                        <span className="font-semibold">Sitzgruppe:</span>{" "} {ticket.seatingGroup}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Sitztyp:</span> {ticket.seatType}
                                    </p>

                                    <p>
                                        <span className="font-semibold">Reihe:</span> {ticket.rowNumber}
                                    </p>

                                    <p>
                                        <span className="font-semibold">Sitznummer:</span> {ticket.seatNumber}
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QRCodeDemo;
