import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Collapse,
    IconButton,
    TextField,
    Box,
    Divider,
    TextareaAutosize
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {styled} from '@mui/material/styles';
import Header from '../HeaderComponents/Header';
import urlConfig from '../../urlConfig';


const OrganizerView = () => {
    const location = useLocation();
    const {firstName} = location.state || {};
    const [localSellers, setLocalSellers] = useState([]);
    const [events, setEvents] = useState([]);
    const [expandedEvent, setExpandedEvent] = useState(null);
    const [newEvent, setNewEvent] = useState({name: '', date: '', description: '', detailedDescription: ''});
    const [dateTimeRows, setDateTimeRows] = useState([{date: '', time: ''}]);
    const [formErrors, setFormErrors] = useState({
        name: false,
        description: false,
        detailedDescription: false,
        date: false,
        time: false
    });

    const organizer = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLocalSellers(organizer.localSellers);

                const eventResponse = await fetch(`${urlConfig.apiBaseUrl}/Event/Customer/${organizer.id}`);
                const eventData = await eventResponse.json();
                setEvents(eventData);
            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
            }
        };

        fetchData();
    }, []);

    const handleDateTimeChange = (index, field, value) => {
        const updatedRows = [...dateTimeRows];
        updatedRows[index][field] = value;
        setDateTimeRows(updatedRows);
    };

    const handleAddRow = () => {
        setDateTimeRows([...dateTimeRows, {date: '', time: ''}]);
    };

    const handleExpandClick = (eventId) => {
        setExpandedEvent(expandedEvent === eventId ? null : eventId);
    };

    const handleEventChange = (field, value) => {
        setNewEvent({...newEvent, [field]: value});
    };

    const validateForm = () => {
        const errors = {
            name: !newEvent.name,
            description: !newEvent.description,
            detailedDescription: !newEvent.detailedDescription,
            date: dateTimeRows.some(row => !row.date),
            time: dateTimeRows.some(row => !row.time)
        };
        setFormErrors(errors);
        return Object.values(errors).every((error) => !error);
    };

    const ExpandMore = styled((props) => {
        const {expand, ...other} = props;
        return <IconButton {...other} />;
    })(({theme, expand}) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const appendNewEvent = (updatedEvent) => {
        setEvents((prevEvents) => [...prevEvents, updatedEvent]);
    };

    const handleAddEvent = async () => {
        if (!validateForm()) {
            return; // Exit if validation fails
        }

        const eventData = {
            name: newEvent.name,
            description: newEvent.description,
            detailed_description: newEvent.detailedDescription,
            picture: "https://ticket4u.at/pictures/concert.jpg",
            organizerId: organizer.id,
        };

        try {
            const createdEvent = await addEventApiCall(eventData);

            if (createdEvent && createdEvent.id) {
                const eventId = createdEvent.id;

                const random = Math.random();
                const dateData = dateTimeRows.map(row => ({
                    date: new Date(`${row.date}T${row.time}`),
                    locationId: random < 0.5 ? 1 : 2, // TODO: LOCATION Input Feld für jedes Datum hinzufügen und mitschicken
                }));
                //console.log("DATE INPUT", JSON.stringify(dateData));

                const addDatesApiResponse = await addDatesToEventApiCall(eventId, dateData);  // add Dates to Event

                const updatedEvent = {...createdEvent, eventDates: addDatesApiResponse};
                appendNewEvent(updatedEvent);  // update show events grid

                setNewEvent({name: '', date: '', description: '', detailedDescription: ''});
                setDateTimeRows([{date: '', time: ''}]);
            } else {
                console.error('Failed to retrieve event ID');
            }
        } catch (error) {
            console.error('Error adding event and dates:', error);
        }
    };

    const addEventApiCall = async (eventData) => {
        try {
            const response = await fetch('${urlConfig.apiBaseUrl}/Event', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('token'),
                },
                body: JSON.stringify(eventData),
            });
            if (response.ok) {
                const data = await response.json();
                console.log(`Event successfully added by organizerId "${organizer.id}":`, data);
                return data;
            } else {
                console.error('Failed to add event:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('Error adding event:', error);
            return null;
        }
    };

    const addDatesToEventApiCall = async (eventId, dateData) => {
        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/Date/dates/${eventId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('token'),
                },
                body: JSON.stringify(dateData),
            });
            if (response.ok) {
                console.log('Dates successfully added to event:', eventId);
                return await response.json();
            } else {
                console.error('Failed to add dates:', response.statusText);
            }
        } catch (error) {
            console.error('Error adding dates to event:', error);
        }
    };

    return (
        <Box className="bg-white-200 min-h-full w-full">
            <Box sx={{width: '100%', position: 'fixed', top: 0, zIndex: 1100}}>
                <Header firstName={firstName}/>
            </Box>
            <Container maxWidth="lg" disableGutters className="mt-[6%]">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} container direction="column" spacing={2}>
                        {/* LocalSeller list */}
                        <Grid item>
                            <Card sx={{borderRadius: '1rem'}}>
                                <CardContent>
                                    <Typography variant="h5">Local Seller List</Typography>
                                    {localSellers.map((localSeller) => (
                                        <Typography key={localSeller.id}>
                                            {localSeller.name}: {localSeller.phone_number}
                                        </Typography>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* Add Event */}
                        <Grid item>
                            <Card sx={{borderRadius: '1rem'}}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Event hinzufügen
                                    </Typography>
                                    <TextField
                                        label="Event Name"
                                        type={"search"}
                                        value={newEvent.name}
                                        onChange={(e) => handleEventChange('name', e.target.value)}
                                        fullWidth
                                        margin="normal"
                                        error={formErrors.name}
                                        helperText={formErrors.name && "Event Name ist erforderlich"}
                                    />
                                    <Box display="flex" alignItems="center" gap={2}>
                                        {/* Description Box */}
                                        <Box flex={1}>
                                            <TextField
                                                label="Description"
                                                value={newEvent.description}
                                                onChange={(e) => handleEventChange('description', e.target.value)}
                                                fullWidth
                                                margin="normal"
                                                multiline
                                                rows={1}
                                                error={formErrors.description}
                                                helperText={formErrors.description && "Description ist erforderlich"}
                                            />
                                            <TextField
                                                label="Detailed Description"
                                                value={newEvent.detailedDescription}
                                                onChange={(e) => handleEventChange('detailedDescription', e.target.value)}
                                                fullWidth
                                                margin="normal"
                                                multiline
                                                rows={4}
                                                error={formErrors.detailedDescription}
                                                helperText={formErrors.detailedDescription && "Detailed Description ist erforderlich"}
                                            />
                                        </Box>
                                        <Divider orientation="vertical" flexItem/>
                                        {/* Date Box */}
                                        <Box flex="0 0 35%">
                                            <Box display="flex" flexDirection="column" gap={2}>
                                                {dateTimeRows.map((row, index) => (
                                                    <Box key={index} display="flex" alignItems="center" gap={2} mt={2}>
                                                        <TextField
                                                            label="Datum"
                                                            type="date"
                                                            value={row.date}
                                                            onChange={(e) => handleDateTimeChange(index, 'date', e.target.value)}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            //error={formErrors.date}
                                                            //helperText={formErrors.date && "Datum ist erforderlich"}
                                                        />
                                                        <TextField
                                                            label="Uhrzeit"
                                                            type="time"
                                                            value={row.time}
                                                            onChange={(e) => handleDateTimeChange(index, 'time', e.target.value)}
                                                            fullWidth
                                                            style={{maxWidth: '80px', flex: '0 1 auto'}}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            //*error={formErrors.time}
                                                            //helperText={formErrors.time && "Erforderlich"
                                                        />
                                                    </Box>
                                                ))}
                                                <Button
                                                    variant="standard"
                                                    color="primary"
                                                    onClick={handleAddRow}
                                                    style={{height: 'fit-content', fontSize: 22}}
                                                >
                                                    +
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddEvent}
                                        style={{marginTop: '15px'}}
                                    >
                                        Event hinzufügen
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {/* Show Events */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{borderRadius: '1rem'}}>
                            <CardContent>
                                <Typography variant="h5">Added Events</Typography>
                                {events.map((event) => (
                                    <div key={event.id}>
                                        <Grid container direction="row" justifyContent="space-between"
                                              alignItems="center">
                                            <Grid item>
                                                <Typography variant="h6">{event.name}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <ExpandMore
                                                    expand={expandedEvent === event.id}
                                                    onClick={() => handleExpandClick(event.id)}
                                                >
                                                    <ExpandMoreIcon/>
                                                </ExpandMore>
                                            </Grid>
                                        </Grid>
                                        <Collapse in={expandedEvent === event.id} timeout="auto" unmountOnExit>
                                            <Box p={2}>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong>Description:</strong> {event.description || 'N/A'}
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong>Detailed
                                                        Description:</strong> {event.detailed_description || 'N/A'}
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong>Dates and Times:</strong>
                                                </Typography>
                                                <div className="space-y-4"> {/* Container für die Termin-Boxen */}
                                                    {event.eventDates?.length > 0 ? (
                                                        event.eventDates.map((eventDate, index) => (
                                                            <div
                                                                key={index}
                                                                className="p-4 bg-white-100 rounded-lg shadow-md"
                                                            >
                                                                <div className="font-medium">
                                                                    <strong>{eventDate.date ? new Date(eventDate.date).toLocaleDateString() : 'N/A'}</strong>
                                                                    {eventDate.date && (
                                                                        <span> - {new Date(eventDate.date).toLocaleTimeString()}</span>
                                                                    )}
                                                                </div>
                                                                {eventDate.location?.description && (
                                                                    <div className="mt-2 text-sm text-gray-600">
                                                                        <strong>Standort:</strong> {eventDate.location.description}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="p-4 bg-white rounded-lg shadow-md">
                                                            <strong>N/A</strong>
                                                        </div>
                                                    )}
                                                </div>
                                            </Box>
                                        </Collapse>
                                    </div>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default OrganizerView;
