import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from "../HeaderComponents/Header";

const EventDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        id,
        name,
        detailedDescription,
        picture,
        organizer,
        eventDates,
        eventLocation
    } = location.state || {};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="w-screen mx-auto mt-0">
            <div className="w-full mx-0">
                <Header />
            </div>

            <div className="px-[10%] pt-[5%]">
                <div className="flex justify-between items-center gap-5 mt-7 px-5">
                <div className="flex-1">
                    <h1 className="text-3xl mb-2">{name || 'Unbekanntes Event'}</h1>
                    <p className="text-xl mb-2">{detailedDescription || 'Keine detaillierte Beschreibung verfügbar.'}</p>
                    <p className="text-xl">Organisiert von: {organizer || 'Unbekannt'}</p>
                </div>
                <div className="max-w-xs flex-shrink-0">
                    <img src={picture} alt={name} className="w-full h-auto max-h-[50vh] rounded-lg"/>
                </div>
            </div>
                <div className="mt-7">
                <h2 className="text-2xl mb-5">Termine</h2>
                <div className="flex gap-7 flex-col">
                    {Array.isArray(eventDates) && eventDates.length > 0 ? (
                        eventDates.map((date, index) => (
                            // div for each date
                            <div key={index}
                                 className="bg-gray-100 hover:bg-white-300 min-h-[12vh] p-5 flex flex-col justify-center items-center text-center rounded-xl shadow-md cursor-pointer"
                                 onClick={() => {
                                     if (date?.seatingPlan === null) {
                                         alert("Für dieses Event ist noch kein Sitzplan angelegt!");
                                     } else {
                                         const data = { date, eventid: id };
                                         navigate('/seatingPlan', { state: data });
                                     }
                                 }}
                            >
                                {/*console.log(JSON.stringify(location.state))*/}
                                <p className="text-lg mb-2">Datum: {new Date(date.date).toLocaleDateString() || 'Unbekannt'}</p>
                                <p className="text-lg mb-2">Uhrzeit: {new Date(date.date).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }) || 'Unbekannt'}</p>
                                <p className="text-lg mb-2">Standort: {date?.location?.description || 'Unbekannt'}</p>
                            </div>
                        ))
                    ) : (
                        <p>Keine Termine verfügbar</p>
                    )}
                </div>
                </div>
            </div>
        </div>
    );
};

export default EventDetails;
