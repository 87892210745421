import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/HeaderComponents/Header';
import Slideshow from './components/Mainpage/Slideshow';
import EventList from './components/Mainpage/EventList';
import Login from './components/LoginView/Login';
import Register from './components/RegisterView/Register';
import AdminView from './components/AdminView/AdminView';
import OrganizerView from './components/OrganizerView/OrganizerView';
import EventDetailedView from './components/EventDetailedView/EventDetailedView';
import SeatingPlanView from './components/SeatingPlanView/SeatingPlanView';
import QRCodeDemo from "./components/QR_CodeDemo/demoqrcode";
import ScanQRCode from "./components/QR_CodeDemo/scanqrcode";
import MolliePaymentDemo from "./components/MolliePayment/paymentDemo";
import './App.css';

const App = () => {
    console.log('Rendering App');
    return (
        <div className="App">
            <main>
                <Routes>
                    <Route path="/" element={
                        <>  <Header />
                            <h1 id="welcome_label">Willkommen!</h1>
                            <Slideshow />
                            <EventList />
                        </>
                    } />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/admin" element={<AdminView />} />
                    <Route path="/organizer" element={<OrganizerView />} />
                    <Route path="/eventDetails" element={<EventDetailedView />}/>
                    <Route path="/seatingPlan" element={<SeatingPlanView />} />
                    <Route path="/paymentDemo" element={<MolliePaymentDemo />} />
                    <Route path="/qrcode" element={<QRCodeDemo />} />
                    <Route path="/scanqrcode" element={<ScanQRCode />} />
                </Routes>
            </main>
        </div>
    );
};

export default App;